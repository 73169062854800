import React, { useContext } from 'react'
import { Link, navigate } from 'gatsby'
import styled from 'styled-components'
import { TransitionContext } from '../components/context/transition-context'

import Layout from '../components/layout'

const NotFoundPage = () => {
  // eslint-disable-next-line no-unused-vars
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)

  return (
    <Layout>
      <Outer>
        <h3>404</h3>
        <p> The page you are looking for doesn't exist</p>
        <NotFoundButton
          to="/"
          onClick={event => {
            event.preventDefault()
            setTransitionActive(true)
            setTimeout(() => {
              navigate(`/`)
              setTransitionActive(false)
            }, 500)
          }}
        >
          {' '}
          Back to home page
        </NotFoundButton>
      </Outer>
    </Layout>
  )
}

export default NotFoundPage

const Outer = styled.section`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    font-size: 100px;
    color: ${props => props.theme.colours.pink};
  }

  p {
    color: ${props => props.theme.colours.darkGrey};
  }

  ${Link} {
    color: ${props => props.theme.colours.darkGrey};
  }
`

const NotFoundButton = styled(Link)`
  background: transparent;
  border: 2px solid ${props => props.theme.colours.pink};
  padding: 20px 50px;
  color: ${props => props.theme.colours.pink};
  font-family: ${props => props.theme.fonts.circular};
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 30px;
  margin-top: 3.5rem;
  display: inline-block;
  transition: background-color 0.25s ease, color 0.25s ease;

  @media (max-width: 650px) {
    font-size: 1.7rem;
    border-radius: 5rem;
    padding: 2.5rem 6rem;
  }

  &:hover {
    background-color: ${props => props.theme.colours.pink};
    color: ${props => props.theme.colours.white};
  }
`
